'use client';

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './AudioPlayer.module.scss';

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${String(remainingSeconds).padStart(2, '0')}`;
};

type PlayerState = {
  playing: boolean,
  currentTime: number,
  duration: number,
};

const AudioPlayer = ({ data, className, darkMode }: { data: string, className?: string, darkMode?: boolean }) => {
  const playerRef = useRef<HTMLAudioElement>(null);
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    playerRef.current?.duration && setDuration(playerRef.current?.duration);
  }, [playerRef.current?.duration]);

  const [playerState, setPlayerState] = useState<PlayerState>({
    playing: false,
    currentTime: 0,
    duration,
  });
  const handlePlay = () => {
    playerRef.current && playerRef.current.play();
    setPlayerState(prev => ({
      ...prev,
      playing: true,
    }));
  };
  const handlePause = () => {
    playerRef.current && playerRef.current.pause();
    setPlayerState(prev => ({
      ...prev,
      playing: false,
    }));
  };

  const handleTimeUpdate = () => {
    playerRef.current && setPlayerState(prev => ({
      ...prev,
      currentTime: playerRef.current ? playerRef.current.currentTime : 0,
      duration: playerRef.current ? playerRef.current.duration : 0,
    }));
  };

  return (
    <div className={classNames(styles.player, className)}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={playerRef}
        className={styles.assistant__voice}
        controls
        hidden
        onTimeUpdate={handleTimeUpdate}
        onEnded={handlePause}
        preload='metadata'
        onLoadedMetadata={handleTimeUpdate}
      >
        <source
          src={data}
          type='audio/mp3'
        />
      </audio>
      <div className={classNames(styles.track, {
        [styles.darkMode]: darkMode,
      })}
      >
        <button
          type='button'
          aria-label='play example of the assistant voice'
          onClick={playerState.playing ? handlePause : handlePlay}
          className={styles.playButton}
        >
          {playerState.playing ? (
            <svg>
              <use
                xlinkHref='/media/audio_pause.svg#pauseSVG'
                href='/media/audio_pause.svg#pauseSVG'
              />
            </svg>
          ) : (
            <svg>
              <use
                xlinkHref='/media/audio_play.svg#playSVG'
                href='/media/audio_play.svg#playSVG'
              />
            </svg>
          )}
        </button>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            style={{ width: `${((playerState.currentTime * 100) / playerState.duration) || 0}%` }}
          />
        </div>
        <p
          className={styles.time}
        >
          {typeof duration === 'number'
            ? formatTime(Math.round(duration - playerState.currentTime))
            : '0:00'}
        </p>
      </div>
    </div>
  );
};

export default AudioPlayer;
