'use client';

import React, { useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { BenefitsProps } from '@/components/Pages/Home';
import { blurDataUrl } from '../../../constants';
import styles from './Benefits.module.scss';

const Benefits = ({ data, title }: { data: BenefitsProps[], title?: React.ReactNode }) => {
  const [activeButton, setActiveButton] = useState<number>(0);
  const [activeSection, setActiveSection] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changeTab = (id: number) => {
    setIsLoading(true);
    setActiveButton(id);
    setTimeout(() => {
      setIsLoading(false);
      setActiveSection(id);
    }, 100);
  };

  return (
    <section className={styles.benefits}>
      <div className='container'>
        {title && (
          <h2 className='title'>
            {title}
          </h2>
        )}
        <div className={styles.benefits__content}>
          <aside className={styles.sidebar}>
            {data.map(section => (
              <React.Fragment key={section.id}>
                <button
                  key={`button-${section.id}`}
                  type='button'
                  className={classNames(styles.button, {
                    [styles.active]: section.id === activeButton,
                  })}
                  onClick={() => activeButton !== section.id && changeTab(section.id)}
                >
                  {section.buttonTitle}
                  <svg className={styles.dropdownIcon}>
                    <use
                      xlinkHref='/media/chevron-right.svg#chevronRight'
                      href='/media/chevron-right.svg#chevronRight'
                    />
                  </svg>
                </button>
                <div
                  key={`section-${section.id}`}
                  className={styles.benefit_mobile}
                >
                  {section.columns.map(column => (
                    <div
                      key={column.id}
                      className={classNames(styles.column, {
                        [styles.column_fullWidth]: section.columns.length === 1,
                      })}
                    >
                      {column.title && <p className={styles.column__title}>{column.title}</p>}
                      <p className={styles.column__text}>{column.text}</p>
                    </div>
                  ))}
                  <div className={styles.image}>
                    <Image
                      fill
                      src={section.image}
                      alt=''
                      placeholder='blur'
                      blurDataURL={blurDataUrl}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </aside>
          <div className={styles.content}>
            {data.map(section => (
              <div
                key={section.id}
                className={classNames(styles.benefit, {
                  [styles.active]: section.id === activeSection,
                  [styles.startHiding]: isLoading,
                })}
              >
                {section.columns.map(column => (
                  <div
                    key={column.id}
                    className={classNames(styles.column, {
                      [styles.column_fullWidth]: section.columns.length === 1,
                    })}
                  >
                    {column.title && <p className={styles.column__title}>{column.title}</p>}
                    <p className={styles.column__text}>{column.text}</p>
                  </div>
                ))}
                <div className={styles.image}>
                  <Image
                    fill
                    src={section.image}
                    alt=''
                    placeholder='blur'
                    blurDataURL={blurDataUrl}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
