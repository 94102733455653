import React from 'react';
import Button, { ButtonSize, ButtonVariants } from '@/components/Button';

type ContactButtonProps = {
  children: React.ReactNode;
  className?: string;
  variant?: ButtonVariants;
  size?: ButtonSize;
};
const ContactButton = ({
  children,
  className,
  variant,
  size,
}: ContactButtonProps) => (
  <Button
    variant={variant}
    className={className}
    link='/contact_us'
    size={size}
  >
    {children}
  </Button>
);

export default ContactButton;
