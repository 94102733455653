import React from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import styles from './Button.module.scss';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT = 'text',
}

export enum ButtonSize {
  SMALL = 'smallSize',
  DEFAULT = 'defaultSize',
  LARGE = 'largeSize',
}

export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

type ButtonProps = {
  className?: string,
  children: React.ReactNode,
  variant?: ButtonVariants,
  icon?: React.ReactNode,
  iconPosition?: ButtonIconPosition,
  iconSize?: {
    width: number,
    height: number,
  }
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  size?: ButtonSize,
  link?: string,
  disabled?: boolean,
  type?: 'button' | 'submit',
};

const Button = ({
  className,
  children,
  variant = ButtonVariants.PRIMARY,
  icon,
  iconPosition = ButtonIconPosition.RIGHT,
  iconSize,
  onClick,
  size = ButtonSize.DEFAULT,
  link = undefined,
  disabled = false,
  type = 'button',
}: ButtonProps) => {
  const classes = {
    [styles.primary]: variant === ButtonVariants.PRIMARY,
    [styles.secondary]: variant === ButtonVariants.SECONDARY,
    [styles.text]: variant === ButtonVariants.TEXT,
    [styles.withIcon]: icon,
    [styles.withIcon_left]: iconPosition === ButtonIconPosition.LEFT,
    [styles.largeSize]: size === ButtonSize.LARGE,
    [styles.defaultSize]: size === ButtonSize.DEFAULT,
    [styles.smallSize]: size === ButtonSize.SMALL,
    [styles.disabled]: disabled,
  };

  return (link ? (
    <Link
      href={link}
      type='button'
      className={classnames(styles.button, className, classes)}
    >
      {children}
      {icon && (
        <div
          className={styles.icon}
          style={{
            width: iconSize?.width,
            height: iconSize?.height,
          }}
        >
          {icon}
        </div>
      )}
    </Link>
  ) : (
    <button // eslint-disable-next-line react/button-has-type
      type={type}
      className={classnames(styles.button, className, classes)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      {icon && (
        <div
          className={styles.icon}
          style={{
            width: iconSize?.width,
            height: iconSize?.height,
          }}
        >
          {icon}
        </div>
      )}
    </button>
  ));
};

export default Button;
